import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';

import {
  BasicSchoolDto,
  Client,
  CreateUserDto,
  GetSchoolClassDto,
  UpdateUserDto,
  UserDto,
} from '@/api/types/api';
import { THERAPY_CLASSES } from '@/utils/common';
import axios from 'axios';
import dayjs from 'dayjs';
import download from 'downloadjs';
import {
  API_CLIENTS,
  API_PDF_BOOK,
  API_PDF_EVALUATION,
  API_RESET_PASSWORD,
  API_RESULTS,
  API_USERS,
} from '../../utils/api';

const name = 'school';

@Module({ namespaced: true, name })
export default class SchoolModule extends VuexModule {
  teachers: UserDto[] = [];
  classStudents: UserDto[] = [];
  patients: UserDto[] = [];
  school: BasicSchoolDto = {
    name: '',
    admins: [],
    classes: [] as GetSchoolClassDto[],
    licenseUntil: '',
    clientType: Client.school,
    schoolId: null,
    slug: '',
    country: '',
    isTutorialOnly: false,
    validTests: 0,
    classLimit: 0,
    sharePatients: false,
  };

  chosenClass: GetSchoolClassDto | null = null;

  get schoolTeacher(): UserDto[] {
    return this.teachers;
  }

  get myStudents(): UserDto[] {
    return this.classStudents;
  }

  get classes(): string[] {
    if (this.school.clientType === Client.school) {
      return this.school.classes?.map((c) => c.name) ?? [];
    } else {
      return THERAPY_CLASSES;
    }
  }

  get schoolName(): string {
    return this.school.name;
  }

  get adminIds(): string[] {
    return this.school.admins ?? [];
  }

  get suffix(): string {
    return `_${this.school.schoolId}`;
  }

  get clientType(): Client {
    return this.school.clientType;
  }

  get classLimit(): number {
    return this.school.classLimit ?? 0;
  }

  get licenseExpired(): boolean {
    const now = new Date();
    // INFO if now is greater than license expired
    return now.getTime() > new Date(this.school.licenseUntil).getTime();
  }

  get validDaysLeft(): number {
    const now = dayjs().startOf('day');
    const end = dayjs(this.school.licenseUntil).startOf('day');
    return end.diff(now, 'day');
    // return Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  get shouldFetchSchoolInfo(): boolean {
    return (
      this.school.name === '' &&
      this.school.licenseUntil === '' &&
      this.school.schoolId === null
    );
  }

  get isTutorialOnlyVersion(): boolean {
    return this.school.isTutorialOnly.toString() === 'true';
  }

  get schoolId(): string | null {
    return this.school.schoolId ?? null;
  }

  get slug(): string {
    return this.school.slug;
  }

  get hasNoValidTestsLeft(): boolean {
    const numOfTests = this.school.validTests ?? 0;
    return numOfTests <= 0;
  }

  get hasMissingLevelInStudents(): boolean {
    return this.classStudents.some(
      (student) => student.level === undefined || student.level === null,
    );
  }

  get isSchoolClient(): boolean {
    return this.school.clientType === Client.school;
  }

  get isTherapyClient(): boolean {
    return this.school.clientType === Client.therapy;
  }

  get selectedClass(): GetSchoolClassDto | null {
    return this.chosenClass;
  }

  get schoolSuffix(): string {
    return `${this.selectedClass?.name}_${this.schoolId}`;
  }

  get sharePatients(): boolean {
    return this.school.sharePatients ?? false;
  }

  get schoolClasses(): GetSchoolClassDto[] {
    return this.school.classes;
  }

  get isLicenseTwoWeeksExpired(): boolean {
    const licenseEnd = dayjs(this.school.licenseUntil).startOf('day');
    const licensePlusTwoWeeks = licenseEnd.add(2, 'week');
    const now = dayjs().startOf('day');
    return now.isAfter(licensePlusTwoWeeks);
  }

  @Mutation
  setChosenClass(selectedClass: GetSchoolClassDto | null): void {
    this.chosenClass = selectedClass;
  }

  @MutationAction({ rawError: true })
  async getBasicSchoolInfo(schoolName: number | string | null): Promise<any> {
    if (schoolName === null || schoolName === '') return;
    const res = await axios.get(`${API_CLIENTS}/${schoolName}/basic-info`, {
      headers: { 'Content-Type': 'application/json' },
    });
    const school = res.data;
    localStorage.setItem('version', school.country);
    return { school: school };
  }

  @Action
  async decreaseValidTests(slug: string): Promise<UserDto[]> {
    try {
      return await axios.patch(`${API_CLIENTS}/valid-tests/${slug}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  @Action
  async getTeachersOrTherapists(): Promise<UserDto[]> {
    const res = await axios.get(`${API_USERS}/filter/${this.schoolId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return res.data;
  }

  @Action({ rawError: true })
  async getStudents(grade?: string): Promise<UserDto[]> {
    const students = await axios
      .get(`${API_USERS}/filter?grade=${grade}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data);
    return students;
  }

  @MutationAction({ rawError: true })
  async getClassStudents(grade?: string): Promise<any> {
    if (!grade) return { classStudents: [] };

    const students = await axios
      .get(`${API_USERS}/filter/${this.schoolId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          grade: grade,
        },
      })
      .then((res) => res.data);

    return {
      classStudents: students,
    };
  }

  @MutationAction({ rawError: true })
  async getPatients(): Promise<any> {
    const data: UserDto[] = await axios
      .get(`${API_USERS}/patients/${this.schoolId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          shared: this.sharePatients,
        },
      })
      .then((res) => res.data);
    return {
      patients: data,
    };
  }

  @Action({ rawError: true })
  async addUser(createUser: CreateUserDto): Promise<UserDto[]> {
    const res = await axios.post(`${API_USERS}/${this.schoolId}`, createUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const teachers: UserDto[] = res.data;
    return teachers;
  }

  @Action({ rawError: true })
  async updateUser(args: {
    id: string;
    updateUser: UpdateUserDto;
  }): Promise<UserDto> {
    const res = await axios.patch(`${API_USERS}/${args.id}`, args.updateUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  }

  @Action({ rawError: true })
  async updateClassesOfTeacher(args: {
    schoolId: string;
    teacherClasses: GetSchoolClassDto[];
    teacherId: string;
  }): Promise<UserDto> {
    const res = await axios.patch(
      `${API_CLIENTS}/schools/${args.schoolId}/classes/teacher/${args.teacherId}`,
      args.teacherClasses,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  }

  @Action({ rawError: true })
  async deleteUser(id: string): Promise<any> {
    // INFO also delete test results on deleting user
    await axios.delete(`${API_RESULTS}/${id}`);
    const res = await axios.delete(`${API_USERS}/${this.schoolId}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  }

  @Action({ rawError: true })
  async importStudents(payload: {
    students: CreateUserDto[];
    className: string;
  }): Promise<any> {
    const res = await axios.post(
      `${API_USERS}/${this.schoolId}/${payload.className}/students`,
      payload.students,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async findOneUser(email: string): Promise<any> {
    const res = await axios.get(`${API_USERS}/email/${email}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data;
  }

  @Action({ rawError: true })
  async deleteAllStudentsOfClass(payload: {
    schoolId: string;
    grade: string;
  }): Promise<any> {
    const res = await axios.delete(
      `${API_CLIENTS}/${payload.schoolId}/students/${payload.grade}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async deleteAllPatientsOfTherapist(payload: {
    schoolId: string;
    grade: string;
  }): Promise<any> {
    const res = await axios.delete(
      `${API_CLIENTS}/${payload.schoolId}/patients/${payload.grade}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async deleteTestResultFromStudent(payload: {
    schoolId: string;
    studentId: string;
  }): Promise<any> {
    const res = await axios.delete(
      `${API_CLIENTS}/teacher/${payload.schoolId}/tests/${payload.studentId}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async deleteAllTestsFromClass(payload: {
    schoolId: string;
    className: string;
  }): Promise<any> {
    const res = await axios.delete(
      `${API_CLIENTS}/teacher/${payload.schoolId}/tests/grade/${payload.className}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async sendPasswordMail(id: string): Promise<any> {
    const res = await axios.post(`${API_RESET_PASSWORD}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  }

  @Action({ rawError: true })
  async getClasslist(filename: string): Promise<void> {
    await axios
      .get(
        `${API_PDF_EVALUATION}/classList/${this.schoolId}/codes/${this.selectedClass?.name}`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        const content = res.headers['content-type'];
        download(res.data, `${filename}`, content);
      });
  }

  @Action({ rawError: true })
  async getSingleCode(id: string): Promise<void> {
    await axios
      .get(
        `${API_PDF_EVALUATION}/classList/${this.schoolId}/${this.selectedClass?.name}/code/${id}`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        const content = res.headers['content-type'];
        download(res.data, `Zugangscode`, content);
        // const file = new Blob([res.data], { type: 'application/pdf;base64' });
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      });
  }

  @Action({ rawError: true })
  async downloadBook(book: string): Promise<void> {
    await axios
      .get(`${API_PDF_BOOK}/${book}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const title = book === 'manual' ? 'LEO-Handbuch' : 'LEO-Praxisbuch';
        const content = res.headers['content-type'];
        download(res.data, title, content);
      });
  }

  @Mutation
  reset(): void {
    this.school = {
      name: '',
      admins: [],
      classes: [],
      licenseUntil: '',
      clientType: Client.school,
      schoolId: null,
      slug: '',
      country: '',
      isTutorialOnly: false,
      validTests: 0,
      classLimit: 0,
      sharePatients: false,
    };
    this.patients = [];
    this.classStudents = [];
    this.teachers = [];
    this.chosenClass = null;
  }
}
