import {
  API_CURRENT_USER,
  API_FORGOT_PASSWORD,
  API_LOGIN,
  API_PDF_EVALUATION,
  API_REFRESH_TOKEN,
  API_RESET_PASSWORD,
  API_USERS,
} from '@/utils/api';
import axios from 'axios';
import download from 'downloadjs';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';
import store from '..';
import {
  Client,
  GetSchoolClassDto,
  KeycloakTokenDto,
  LoginDto,
  ResetPasswordDto,
  Role,
  UpdateUserDto,
  UserDto,
} from '../../api/types';

const name = 'user';

@Module({ namespaced: true, store, name })
export default class UserModule extends VuexModule {
  token?: KeycloakTokenDto = {
    access_token: '',
    refresh_token: '',
    expires_in: 0,
    refresh_expires_in: 0,
    token_type: '',
    ['not-before-policy']: 0,
    session_state: '',
    scope: '',
  };

  user: UserDto = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    role: Role.student,
    doneA: false,
    doneB: false,
    schoolId: '',
    studentId: 0,
    grade: '',
    schoolName: '',
    street: '',
    city: '',
    country: '',
    classes: [],
    clientType: Client.school,
    enableA: false,
    enableB: false,
    level: 0,
    groups: [],
  };

  isTutorialOnly = false;

  activeAudio?: HTMLAudioElement;

  get currentActiveAudio(): HTMLAudioElement | undefined {
    return this.activeAudio;
  }

  get currentUser(): UserDto {
    return this.user!;
  }

  get doneA(): boolean {
    return this.currentUser.doneA;
  }

  get doneB(): boolean {
    return this.currentUser.doneB;
  }

  get enabledA(): boolean {
    return this.currentUser.enableA ?? false;
  }

  get enabledB(): boolean {
    return this.currentUser.enableB ?? false;
  }

  get myClasses(): GetSchoolClassDto[] {
    return this.currentUser.classes;
  }

  @MutationAction({ rawError: true })
  async login(credentials: LoginDto): Promise<any> {
    const result = await axios.post(`${API_LOGIN}`, credentials, {
      headers: { 'Content-Type': 'application/json' },
    });
    const token: KeycloakTokenDto = result.data;
    store.commit('setToken', token);

    return { token };
  }

  @MutationAction({ rawError: true })
  async whoAmI(schoolId: string): Promise<any> {
    const result = await axios.get(
      ` ${API_CURRENT_USER}/${schoolId}/current-user`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    store.commit('login');
    const user: UserDto = result.data;
    return { user: user };
  }

  @Action({ rawError: true })
  async refetchMe(id: string): Promise<any> {
    const result = await axios.get(`${API_USERS}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data: UserDto = result.data;
    this.context.commit('updateAdmin', data);
  }

  @Mutation
  updateAdmin(user: UserDto): void {
    this.user.firstname = user.firstname;
    this.user.lastname = user.lastname;
    this.user.email = user.email;
    this.user.grade = user.grade;
  }

  @MutationAction
  async refreshAccessToken(refreshToken: string): Promise<any> {
    const token = await axios
      .post(
        `${API_REFRESH_TOKEN}`,
        { token: refreshToken },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => response.data);
    return { token };
  }

  @MutationAction
  async updateUser(args: { data: UpdateUserDto; id: string }): Promise<any> {
    const result = await axios.patch(`${API_USERS}/${args.id}`, args.data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const user: UserDto = result.data;
    const updatedUser = { ...this.user, ...user };
    return { user: updatedUser };
  }

  @Mutation
  logout(): void {
    this.user = {
      _id: '',
      username: '',
      firstname: '',
      lastname: '',
      role: Role.student,
      schoolId: '',
      doneA: false,
      doneB: false,
      isTutorialOnly: false,
      schoolName: '',
      street: '',
      city: '',
      classes: [],
      country: '',
      enableA: false,
      enableB: false,
      clientType: Client.school,
      groups: [],
    };
    this.token = {
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      refresh_expires_in: 0,
      token_type: '',
      ['not-before-policy']: 0,
      session_state: '',
      scope: '',
    };
  }

  @Mutation
  setActiveAudio(value: HTMLAudioElement): any {
    this.activeAudio = value;
  }

  @Mutation
  stopActiveAudio(): void {
    if (this.activeAudio?.paused !== undefined) {
      this.activeAudio?.pause();
    }
  }

  @Action({ rawError: true })
  async forgotPassword(email: string): Promise<any> {
    await axios.post(
      `${API_FORGOT_PASSWORD}`,
      { email: email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  @Action({ rawError: true })
  async resetPassword(payload: ResetPasswordDto): Promise<any> {
    await axios.post(`${API_RESET_PASSWORD}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  @Action({ rawError: true })
  async getEvaluationOfForm(args: { form: string; id: string }) {
    await axios
      .get(`${API_PDF_EVALUATION}/${args.id}/${args.form}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const content = res.headers['content-type'];
        download(
          res.data,
          `LEO_Auswertung_Form_${args.form.toUpperCase()}.pdf`,
          content,
        );
      });
  }

  @Action({ rawError: true })
  async getClassEvaluation(payload: {
    schoolId: string;
    className: string;
    form: string;
  }): Promise<any> {
    return await axios
      .get(
        `${API_PDF_EVALUATION}/class-evaluation/${payload.schoolId}/${payload.className}/${payload.form}`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        const content = res.headers['content-type'];
        download(
          res.data,
          `LEO_Auswertung_Form_${payload.form.toUpperCase()}.pdf`,
          content,
        );
      });
  }
}
