import { Client, Role, Test } from '@/api/types/api';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { setSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import store from '../store';
import { Testname } from '../utils/helpers';

Vue.use(VueRouter);

const isSuperAdmin: NavigationGuard = async (
  _to: any,
  _from: any,
  next: any,
) => {
  await (store as any).restored;
  const userModule: UserModule = getModule(UserModule, store);
  if (userModule.currentUser.role === Role.superadmin) {
    next();
  } else {
    next({ name: 'school' });
  }
};

const isAdmin: NavigationGuard = async (_to: any, _from: any, next: any) => {
  await (store as any).restored;
  const userModule: UserModule = getModule(UserModule, store);
  const schoolModule: SchoolModule = getModule(SchoolModule, store);
  const hasAdminRights = schoolModule.adminIds.includes(
    userModule.currentUser._id,
  );

  if (hasAdminRights) {
    next();
  } else {
    next({ name: 'school' });
  }
};

const isParent: NavigationGuard = async (_to: any, _from: any, next: any) => {
  await (store as any).restored;
  const userModule: UserModule = getModule(UserModule, store);
  if (userModule.currentUser.role === Role.parent) {
    next();
  } else {
    next({ name: 'school' });
  }
};

const isParentClient: NavigationGuard = async (
  _to: any,
  _from: any,
  next: any,
) => {
  await (store as any).restored;
  const schoolModule: SchoolModule = getModule(SchoolModule, store);
  if (schoolModule.clientType === Client.parent) {
    next();
  } else {
    next({ name: 'login' });
  }
};

const isTeacherOrTherapistOrAdminOrParent: NavigationGuard = async (
  _to: any,
  _from: any,
  next: any,
) => {
  await (store as any).restored;
  const userModule: UserModule = getModule(UserModule, store);
  if (
    userModule.currentUser.role === Role.teacher ||
    userModule.currentUser.role === Role.therapist ||
    userModule.currentUser.role === Role.admin ||
    userModule.currentUser.role === Role.parent
  ) {
    next();
  } else {
    next({ name: 'school' });
  }
};

const routes: Array<RouteConfig> = [
  // Admin
  {
    path: `/`,
    name: 'info',
    component: () =>
      import(
        /* webPackPrefetch: true*/ /* webpackChunkName: "info" */ `../views/tests/Info.vue`
      ),
  },
  {
    path: '/:schoolName/schule',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        name: 'school',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/admin/Login.vue'),
      },
      {
        path: 'lehrer',
        name: 'teachers',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../views/admin/TeacherPage.vue'
          ),
        beforeEnter: isAdmin,
      },
      {
        path: 'schueler',
        name: 'students',
        component: () =>
          import(
            /* webpackChunkName: "teacher" */ '../views/admin/StudentPage.vue'
          ),
        beforeEnter: isTeacherOrTherapistOrAdminOrParent,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "parent" */ '../views/admin/ParentPage.vue'
          ),
        beforeEnter: isParent,
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () =>
          import(
            /*webPackPrefetch: true */ /*webpackChunkName: "password" */ '../views/admin/ForgotPassword.vue'
          ),
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () =>
          import(
            /*webPackPrefetch: true*/ /* webpackChunkName: "password" */ '../views/admin/ResetPassword.vue'
          ),
      },
      {
        path: 'imprint',
        name: 'imprint',
        component: () =>
          import(
            /*webPackPrefetch: true */ /*webpackChunkName: "adminimprint" */ '../views/admin/AdminImpressum.vue'
          ),
      },
      {
        path: 'groups',
        name: 'groups',
        component: () =>
          import(
            /* webpackChunkName: "parent" */ '../views/superadmin/GroupDashboard.vue'
          ),
        beforeEnter: isSuperAdmin,
      },
      {
        path: 'groups/create',
        name: 'groups-create',
        component: () =>
          import(
            /* webpackChunkName: "parent" */ '../views/superadmin/GroupDashboard.vue'
          ),
        beforeEnter: isSuperAdmin,
      },
      {
        path: 'groups/:id',
        name: 'groups-detail',
        component: () =>
          import(
            /* webpackChunkName: "parent" */ '../views/superadmin/GroupDashboard.vue'
          ),
        beforeEnter: isSuperAdmin,
      },
    ],
  },
  {
    path: '/:schoolName',
    component: {
      render(c: (arg0: string) => any) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'login',
        meta: { title: 'Lesikus' },
        component: () =>
          import(
            /*webPackPrefetch: true */ /* webpackChunkName: "test" */ '../views/tests/Login.vue'
          ),
      },

      {
        path: 'eltern-info',
        name: 'parentInfo',
        component: () =>
          import(
            /*webPackPrefetch: true*/ /* webpackChunkName: "test" */ '../views/tests/ParentInfo.vue'
          ),
        beforeEnter: isParentClient,
      },
      {
        path: 'start',
        name: 'start',
        component: () =>
          import(
            /*webPackPrefetch: true*/ /* webpackChunkName: "test" */ '../views/tests/Start.vue'
          ),
        // beforeEnter: authGuard,
      },
      {
        path: 'form',
        name: 'form',
        component: () =>
          import(/* webpackChunkName: "test" */ '../views/tests/Start.vue'),
      },
      {
        path: 'tests/:form/',
        name: 'tests',
        component: () =>
          import(
            /* webpackChunkName: "testOptions" */ '../views/tests/TestOptions.vue'
          ),
      },
      {
        path: `tests/:form/${Testname[Test.letters].toLowerCase()}`,
        name: Test.letters,
        component: () =>
          import(
            /* webpackChunkName: "letters" */ `../views/tests/Buchstabenjagd.vue`
          ),
      },
      {
        path: `tests/:form/${Testname[Test.fantasy]
          .toLowerCase()
          .replace('ä', 'ae')
          .replace('ö', 'oe')}`,
        name: Test.fantasy,
        component: () =>
          import(
            /* webpackChunkName: "fantasy" */ `../views/tests/Fantasiewoerter.vue`
          ),
      },
      {
        path: `tests/:form/${Testname[Test.syllables].toLowerCase()}`,
        name: Test.syllables,
        component: () =>
          import(
            /* webpackChunkName: "syllables" */ `../views/tests/Silbensuche.vue`
          ),
      },
      {
        path: `tests/:form/${Testname[Test.words]
          .toLowerCase()
          .replace(' ', '-')
          .replace('ö', 'oe')}`,
        name: Test.words,
        component: () =>
          import(
            /* webpackChunkName: "words" */ `../views/tests/WoerterFinden.vue`
          ),
      },
      {
        path: `tests/:form/${Testname[Test.riddle]
          .toLowerCase()
          .replace('ä', 'ae')
          .replace('ö', 'oe')}`,
        name: Test.riddle,
        component: () =>
          import(
            /* webpackChunkName: "riddle" */ `../views/tests/Woerterraetsel.vue`
          ),
      },
      {
        path: `gratulation`,
        name: 'congratulations',
        component: () =>
          import(
            /* webpackChunkName: "congratulations" */ `../views/tests/Congratulations.vue`
          ),
      },
    ],
  },

  {
    path: `/impressum`,
    name: 'impressum',
    component: () =>
      import(
        /*webPackPrefetch: true*/ /* webpackChunkName: "imprint" */ `../views/tests/Impressum.vue`
      ),
  },
  {
    path: `*`,
    component: () =>
      import(
        /* webPackPrefetch: true*/ /*  webpackChunkName: "notfound" */ `../views/tests/NotFound.vue`
      ),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, _from, next) => {
  await (store as any).restored;
  const userModule: UserModule = getModule(UserModule, store);
  const isLoggedOut = userModule.currentUser._id === '';
  if (!to.fullPath.includes('impressum')) {
    setSchoolPrefix(to.params.schoolName);
  }
  if (
    to.name !== 'login' &&
    to.name !== 'school' &&
    to.name !== 'forgotPassword' &&
    to.name !== 'resetPassword' &&
    to.name !== 'impressum' &&
    to.name !== 'imprint' &&
    to.name !== 'info' &&
    isLoggedOut
  ) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
